<template>
  <div>
    <div class="info1" v-show="content">
      <div class="top1">
        <div class="leftTop1">
          <van-nav-bar left-text="" left-arrow @click-left="onClickLeft"/>
        </div>
        <div class="rightTop1">
          <span class="title1">《热血大乱斗》账号注销</span>
        </div>
      </div>
      <div class="cont">
        <h4 class="commonTitle">该账号可以直接注销，注销前请认真阅读以下提醒</h4>
        <p>为防止误操作，请再次确认是否注销游戏帐号并确认注销后的影响，该影响包括但不限于，具体详见重要提醒</p>
        <p>
          1.游戏账号一旦注销，您将无法再次登录该游戏及不同游戏版本、云游戏等，无法使用该游戏账号对应的角色，且无法恢复。除法律法规和重要提醒另有规定外，您对应游戏账号下的所有信息将会被删除或匿名化处理，您无法再找回也无权要求我们找回您的游戏账号信息。</p>
        <p>
          2.注销游戏帐号在使用期间已产生及未来可能产生的所有收益或权益将会清除。请确保在您注销游戏帐号前已妥善处理您的帐号收益或权益。游戏帐号注销后，我们有权利对该游戏帐号下的全部收益或权益做清除处理，因此产生的后果由您自行承担，前述收益或权益包括但不限于帐号内剩余的所有游戏虚拟货币、虚拟道具及其他虚拟物品及增值服务。</p>
      </div>
      <van-form class="formBtn">
        <van-button class="abandonLogout" type="danger" @click="abandon">放弃注销</van-button>
        &nbsp;
        <van-button class="logout" type="danger" @click="logout">确认注销</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import {NavBar} from "vant";
import Vue from 'vue';

Vue.use(NavBar);
import {
  delUserInfo,
} from "@/api/common/user";
import {Base64} from "js-base64";

export default {
  data() {
    return {
      content: true,
      delState: false, //防止重复点击
      userInfo: undefined,
      url: 'https://play.google.com/store/games'
    };
  },
  created() {
    if (this.$route.query.key) {
      try {
        const jsonStr = Base64.decode(this.$route.query.key);
        this.userInfo = JSON.parse(jsonStr);
        if (this.userInfo.account === undefined || this.userInfo.password === undefined) {
          this.$toast.fail('参数错误！')
          this.content = false
          return false;
        }
      } catch (error) {
        // 解析失败，说明不是有效的JSON格式
        this.$toast.fail('参数错误！')
        this.content = false
        return false;
      }
    } else {
      this.$toast.fail('参数不存在！')
      this.content = false
      return false;
    }
  },
  methods: {
    //  返回
    onClickLeft() {
      this.$router.push({
        path: '/deluser/rxdld/login'
      });
    },
    //  放弃注销
    abandon() {
      //  跳转地址
      window.location.href = this.url
    },
    //  注销操作
    logout() {
      console.log("onSubmit");
      const that = this;
      if (that.delState) {
        return false;
      }
      that.delState = true;
      Toast.loading({
        message: "删除中，请稍等!",
        forbidClick: true,
      });
      this.dellAccountInfo()
    },
    //  跳转地址
    redirectUrl() {
      this.$router.push({
        path: '/deluser/rxdld/success'
      });
    },
    //  封禁账号
    dellAccountInfo() {
      console.log("获取账号：" + this.account)
      console.log("获取账号密码：" + this.password)
      delUserInfo(this.userInfo).then((response) => {
        //  封禁成功区间
        Toast.clear(true)
        this.delState = false
        console.log('进入成功区间，response：' + JSON.stringify(response))
        this.redirectUrl()
      }).catch((response) => {
        //  封禁失败区间
        console.log('进入失败区间，response：' + JSON.stringify(response))
        this.delState = false
        this.$toast.fail(response.message)
      });
    }
  },
};
</script>

<style>
.info1 {
  margin: 0.2rem 0.2rem;
  border: 1px solid #333;
}

.top1 {
  font-size: 0.2rem;
  font-weight: bold;
  top: 10%;
  text-align: center;
  border-bottom: 2px solid #333;
  width: 100%;
  height: 0.4rem;
}

.leftTop1 {
  width: 10%;
  display: inline-block;
  font-size: large;
}

.rightTop1 {
  width: 90%;
  display: inline-block;
  margin-top: 0.1rem;
}

.title1 {
  font-weight: bold;
  font-size: 15px;
}

.cont {
  font-size: 0.15rem;
}

.van-nav-bar__left, .van-nav-bar__right {
  position: absolute;
  top: -13px;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 10rem;
  cursor: pointer;
}

.van-nav-bar .van-icon {
  color: black;
  font-size: x-large;
}

.van-nav-bar__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}

.abandonLogout {
  width: 45%;
  border-color: #D7D7D7;
  background-color: #D7D7D7;
  margin-left: -0.08rem;
  border: 1px solid #D7D7D7;
  border-radius: 7px;
  color: black;
}

.logout {
  width: 45%;
  border-color: #02A7F0;
  background-color: #02A7F0;
  border: 1px solid #D7D7D7;
  border-radius: 7px;
}

.formBtn {
  margin-left: 0.2rem;
  margin-bottom: 1.5rem;
}
</style>
